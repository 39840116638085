.js-select,
.js-custom-select {
    height: 50px !important;
    option {
        display: none;
    }
}

.select2-dropdown {
    border-color: #000;
}



.select2-container {
    .select2-selection--single {
        height: 50px;

        &:before {
            content: "\f109";
            font-family: "Flaticon";
            position: absolute;
            right: 24px;
            top: 36%;
            font-size: 12px;
            color: #1F92C7;
        }
    }
    .select2-selection--multiple {
        min-height: 36px;
        .select2-selection__rendered {
            overflow: unset;
            white-space: unset;
        }

        &:before {
            content: "\f109";
            font-family: "Flaticon";
            position: absolute;
            right: 24px;
            top: 36%;
            font-size: 12px;
            color: #1F92C7;
        }
    }
}

.select2-results__option[aria-selected=true] {
    display: none;
}

.select2-selection__choice__remove {
    color: #ff595b !important;
    float: right;
    margin-left: 5px;
}

.select2-container--default {
    &.select2-container--open.select2-container--below {
        .select2-selection--single {
            border-color: #000;
        }
    }

    .select2-selection--single {
        border-radius: 10px;
        outline: none;

        .select2-selection__arrow {
            display: none;
        }
        .select2-selection__rendered {
            line-height: 50px;
        }
    }

    .select2-selection--multiple {
        border-radius: 10px;
    }

    .select2-search--dropdown {
        .select2-search__field {
            outline-color: transparent;
            font-family: "Open sans", sans-serif;
        }
    }
}

li.select2-search {
    min-height: 36px;
    input {
        min-height: inherit;
    }
}

.select2-selection {
    border-radius: 10px;
    background-color: #FFF;
    border: 1px solid #CECECE;

    ::placeholder {
        color: #9EA5A9;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #9EA5A9
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #9EA5A9
    }
}

.select2-selection__rendered {
    .select2-selection__choice {
        border-radius: 8px !important;
        background-color: #F2F2F2 !important;
        border: 1px solid #BDBDBD !important;
        font-size: 16px;
        color: #2690C1;
        font-weight: 500;
        margin-top: 10px !important;
    }
}

.select2-results__options .select2-results__option[aria-disabled=true] {
   display: none;
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 40vh;
}
