// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .text-xs {
        @include modifier($font-size, 'font-size');
    }

    .d-xs-none {
        display: none;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .text-sm {
        @include modifier($font-size, 'font-size');
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .text-md {
        @include modifier($font-size, 'font-size');
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .text-lg {
        @include modifier($font-size, 'font-size');
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .text-xl {
        @include modifier($font-size, 'font-size');
    }
}


@media (max-width: 768px) {
    .pagination{
        .page-item {
            .page-link {
                &.pagination-text
                {
                    width: unset;
                }
                &:hover {
                    background-color: unset;
                }
            }
        }
    }

    .write-review {
        .review-content {
            padding: 20px;
        }
    }

    .footer-wrapper {
        #footer {
            ul.nav {
                &:not(:first-child),
                &:not(:last-child) {
                    padding-left: unset;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .text-md-left {
        text-align: left;
    }

    #home_content_b {
        #right-decoration{
            &:after {
                display: none;
            }
        }
    }

    #page-bottom {
        padding: 1.5rem 0;

        &:before,
        &:after {
            display: none;
        }
    }
}

@media (max-width: 1260px) {
    body {

        header ul.nav li.nav-item a.nav-link {
            font-size: 12px;
            padding: 0.5rem 0.4rem;
        }

        .custom-modal {
            padding: 35px;
        }

        button.close {
            background: none;
            border: none;

        }

        &.has-sidebar {
            .page-sidebar {
                left: 0;
            }
        }

        .page-sidebar {
            position: fixed;
            display: block;
            z-index: 999;
            width: 320px;
            top: 0;
            left: -320px;
            background: $white;
            transition: all 0.3s ease 0s;
            min-height: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

            .close {
                position: absolute;
                top: 16px;
                right: 16px;
                opacity: 1;
            }

            .sidebar-inner {
                padding: 42px 15px 20px 15px;

                .nav-item {

                    .child-items {
                        padding-left: 20px;

                        a {
                            font-weight: 500;
                            padding: 0 0 5px;
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    &.active {
                        & > a {
                            position: relative;

                            &:after {
                                content: '';
                                width: 156px;
                                height: 1px;
                                background: $green;
                                position: absolute;
                                bottom: 7px;
                                left: 0;
                            }
                        }

                        & > a {
                            color: $green;
                        }

                        .child-items {
                            display: block;
                        }
                    }
                }
            }
        }

        .footer-wrapper {
            margin-top: auto;
        }
    }
}

@media(max-width: 1349.99px) {
    #home_content_a{
        &:after {
            display: none;
        }
    }

    header {
        ul.nav li.nav-item a.nav-link {
            font-size: 12px;
            padding: 0.5rem 0.4rem;
        }

        div.auth-menu {
            font-size: 12px;
        }
    }

    .footer-wrapper {
        margin-top: auto;
        #footer {
            ul.nav {
                &:not(:first-child),
                &:not(:last-child) {
                    padding-left: 25px;
                }
            }
        }
    }
}
