/*
Flaticon icon font: Flaticon
Creation date: 28/07/2020 10:05
*/

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
            url("../fonts/Flaticon.woff2") format("woff2"),
            url("../fonts/Flaticon.woff") format("woff"),
            url("../fonts/Flaticon.ttf") format("truetype"),
            url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-134547:before {
    content: "\f101";
}
.flaticon-account:before {
    content: "\f102";
}
.flaticon-agreement:before {
    content: "\f103";
}
.flaticon-badge:before {
    content: "\f104";
}
.flaticon-carpenter:before {
    content: "\f105";
}
.flaticon-categories-list:before {
    content: "\f106";
}
.flaticon-clock:before {
    content: "\f107";
}
.flaticon-discount:before {
    content: "\f108";
}
.flaticon-download:before {
    content: "\f109";
}
.flaticon-email:before {
    content: "\f10a";
}
.flaticon-euro:before {
    content: "\f10b";
}
.flaticon-eye:before {
    content: "\f10c";
}
.flaticon-farm:before {
    content: "\f10d";
}
.flaticon-floppy-disk:before {
    content: "\f10e";
}
.flaticon-global:before {
    content: "\f10f";
}
.flaticon-guarantee:before {
    content: "\f110";
}
.flaticon-hidden:before {
    content: "\f111";
}
.flaticon-home:before {
    content: "\f112";
}
.flaticon-industry:before {
    content: "\f113";
}
.flaticon-info:before {
    content: "\f114";
}
.flaticon-legal-document:before {
    content: "\f115";
}
.flaticon-list-1:before {
    content: "\f116";
}
.flaticon-list-2:before {
    content: "\f117";
}
.flaticon-list:before {
    content: "\f118";
}
.flaticon-location:before {
    content: "\f119";
}
.flaticon-lock:before {
    content: "\f11a";
}
.flaticon-loupe:before {
    content: "\f11b";
}
.flaticon-mathematics:before {
    content: "\f11c";
}
.flaticon-needle:before {
    content: "\f11d";
}
.flaticon-notification-1:before {
    content: "\f11e";
}
.flaticon-notification:before {
    content: "\f11f";
}
.flaticon-paper-clip:before {
    content: "\f120";
}
.flaticon-placeholder:before {
    content: "\f121";
}
.flaticon-spa:before {
    content: "\f122";
}
.flaticon-spotlight:before {
    content: "\f123";
}
.flaticon-steering-wheel:before {
    content: "\f124";
}
.flaticon-suitcase:before {
    content: "\f125";
}
.flaticon-trash:before {
    content: "\f126";
}
.flaticon-user:before {
    content: "\f127";
}
.flaticon-wifi:before {
    content: "\f128";
}
.flaticon-work:before {
    content: "\f129";
}
