$gray-200: #edeeef;
$gray-300: #788388;
$gray-400: #525455;
$gray-500: #4c5463;
$gray-600: #788388;
$gray-700: #041133;
$gray-777: #777;
$gray-788: #788287;
$gray-800: #242b39;
$gray-900: #000000;
$gray-bd: #BDBDBD;
$gray-ce: #CECECE;
$green: #57B757;
$green-gl: #16A765; 
$yellow: #fc7958;
$yellow-600: #D69E2E;
$star: #FFBF71;
$star-o: #C0C4D1;
$red: #ff595b;
$red-200: #F75A5A;
$orange-light: #fff0ee;
$base: #fffefe;
$cyan: #68aebc;
$blue: #2690C1;
$white: #fff;

$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    '2-5': ($spacer * 2.5)
  ),
  $spacers
);

$colors: (
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-777': $gray-777,
    'gray-788': $gray-788,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
    'green': $green,
    'green-gl': $green-gl,
    'yellow': $yellow,
    'yellow-600': $yellow-600,
    'star': $star,
    'star-o': $star-o,
    'red': $red,
    'red-200': $red-200,
    'orange-light': $orange-light,
    'base': $base,
    'cyan': $cyan,
    'blue': $blue,
    'white': $white,
);

$font-size: (
    9: 9px,
    12: 12px,
    14: 14px,
    16: 16px,
    18: 18px,
    20: 20px,
    24: 24px,
    30: 30px,
    36: 36px,
    40: 40px,
    48: 48px,
    60: 60px,
    80: 80px,
    206: 206px,
);

$z-index: (
    10: 10,
    20: 10,
    30: 30,
    40: 40,
    50: 50,
    60: 60,
    70: 70,
    80: 80,
    90: 90,
);

$font-weight: (
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700
) ;

$grid-breakpoints: (
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 576px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 992px,
    // Extra large screen / wide desktop
    xl: 1350px
);

$breadcrumb-divider: quote(">");

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1350px
);

@mixin modifier($map, $attribute) {
    @each $name, $hex in $map {
      &-#{$name} {
        #{$attribute}: $hex;
      }
    }
}
