@import '../css/flaticon.css';
@import 'select.scss';

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular'), url('../fonts/Metropolis-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 500;
    src: local('Metropolis Medium'), url('../fonts/Metropolis-Medium.woff') format('woff');
}

#euro {
    margin-bottom: -2px;
}

body {
    color: $gray-777;
    font-family: "Metropolis", sans-serif;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display:flex;
    flex-direction:column;

    #header {
        &.top-mobile {
            #logo {
                height: 50px;
                width: 210px;
            }
        }
    }

    &.has-overlay {
        overflow: hidden;

        .overlay {
            display: block;
        }
    }

    .page-sidebar {
        display: none;
    }

    .overlay {
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 500;
        display: none;
    }
}

html{
    margin: 0;
    height: 100%;
}

.footer-wrapper {
    margin-top: auto;
    #footer {
        ul.nav {
            &:not(:first-child),
            &:not(:last-child) {
                padding-left: 100px;
            }
        }
    }
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-m-90 {
    transform: rotate(-90deg);
}

.day-label {
    min-width: 120px;
}

.imagelightbox-arrow {
    background: none;
}

.form-control:focus {
    box-shadow: none;
}

.create-panel {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0 2px 4px 1px rgba(0,0,0,0.1);
}

.attribute-panel {
    box-sizing: border-box;
    border-radius: 20px;
    border: 2px solid #2690C1;
}

.extra-information-panel {
    border-radius: 20px;
    background-color: #EBF9FF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

a:focus,
button:focus {
    outline: none;
}

.description-text a {
    color: $blue !important;
    &:hover {
        color: $gray-777 !important;
        text-decoration: none !important;
    }
}

a {
    color: $gray-777;
    &.text-success {
        &:hover {
            color: $green !important;
        }

        &:focus {
            color: $green !important;
        }
    }

    &:hover {
        text-decoration: none !important;
    }

    &:focus {
        text-decoration: none !important;
    }
}

.breadcrumb-item{
    &:before {
        color: $gray-777 !important;
    }

    &:last-of-type {
        font-size: 18px;
        font-weight: 500;
        &:before {
            content: ">";
        }
    }
}

.breadcrumb {
    background: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px;
    font-size: 16px;
    color: $gray-777;

    .breadcrumb-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
            color: $gray-777;
        }
    }
}

.dropdown-item {
    &:active {
        color: unset !important;
        background-color: #f8f9fa !important;
    }
}

.btn-success-gl {
    color: $white;
    background-color: $green-gl;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #006b3a;
        border-color: #005e33;
    }
}

.text {
    @include modifier($colors, 'color');
    @include modifier($font-size, 'font-size');
}

.z {
    @include modifier($z-index, 'z-index');
}

.text-weight {
    @include modifier($font-weight, 'font-weight');
}

.text-line-1 {
    line-height: 1;
}

.font-sans {
    font-family: "Open sans", sans-serif;
}

.rounded-8 {
    border-radius: 8px;
}

.form-datetimepicker {
    height: 50px;
    border: 1px solid #CECECE;
    outline: none;

    &::placeholder {
        color: #9EA5A9;
    }

    &::-webkit-input-placeholder {
        color: #9EA5A9;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #9EA5A9
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: #9EA5A9;
    }
}

.exact-budget-field {
    padding: 10px;
    height: 50px;
    width: 105px;
    border: 1px solid #CECECE;
}

.discount-field {
    padding: 10px;
    height: 40px !important;
    width: 75px;
    border: 1px solid #CECECE;
    margin-bottom: 3px;
}

.word-wrap {
    word-wrap: break-word;
}

.border-f-1{
    border-width: 1px;
    border-style: solid;
}

.border-f-2 {
    border-width: 2px;
    border-style: solid;
}


.border-1 {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.border-2 {
    border-bottom-width: 2px;
    border-bottom-style: solid;
}

.border-blue {
    border-color: $blue !important;
}

.border-green {
    border-color: $green !important;
}

.border-gray-ce {
    border-color: $gray-ce !important;
}

.cursor-pointer{
    cursor: pointer;
}

#header {
    &.top-mobile {
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        padding: 10px 0;
        background-color: #fff;
    }

    border-bottom: 3px solid $green;

    .sidebartoggler {
        button {
            background: none;
            border: none;
            padding: 5px;
        }
    }
}

.logo {
    height: 70px;
    max-height: 70px;
    max-width: 270px;
    width: 270px;

    &:after {
        content: "";
        height: auto;
        width: 100%;
        background: url('/img/svg/logo.svg');
        background-repeat: no-repeat;
    }
}

#home_content_a{
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 1126px;
        width: 1026px;
        background: url('/img/svg/how-it-works_bg.svg');
        background-repeat: no-repeat;
        z-index: -1;
    }
}

#home_content_b {
    max-height: 890px;

    #right-decoration{
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: inherit;
            width: 380px;
            max-width: 380px;
            background: url('/img/svg/decoration_bg.svg');
            background-repeat: no-repeat;
        }
    }
}

#home_content_c {
    border-bottom: 2px solid #57B757;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
}

.hiw {
    padding-top: 100px;
}

#hiw-vertical {
    padding-top: 100px;
}

#useful-post-widget {
    padding-top: 100px;
}

.showcase-bg {
    background: url('/img/showcase.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

#category-menu {
    border-radius: 10px;
    background-color: #F4F5F5;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

#advanced-search {
    border-radius: 10px;
    background-color: #F4F5F5;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);

    .btn.dropdown-no-toggle {
        border: 1px solid transparent;
        border-color: #CECECE;
        height: 40px;
        flex: 0 0 10%;
    }

    .btn.dropdown-toggle {
        border: 1px solid transparent;
        border-color: #CECECE;
        height: 40px;
        flex: 0 0 10%;
        &:after {
            content: "\F109";
            font-family: Flaticon;
            font-style: normal;
            font-size: 10px;
            border: unset;
            vertical-align: 0.055em;
        }
    }

    .dropdown {
        background-color: #fff;
    }

    .dropdown-menu-nc {
        padding: 5px 10px 0;
        max-height: 300px;
        overflow-y: scroll;
    }

    .custom-control-label {
        &:before {
            top: 0.05rem;
        }
        &:after {
            top: 0.05rem;
        }
    }

    .advanced-filter-group-link {
        position: absolute;
        top: 10px;
        right: 5px;
    }
}


.view-btn {
    font-weight: 600;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    color: $blue;
    border-bottom: 1px solid;

    &:hover {
        color: $blue;
    }
}

.edit-btn {
    font-weight: 600;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    color: #F0B429;
    border-bottom: 1px solid;

    &:hover {
        color: #F0B429;
    }
}

.cancel-btn {
    font-weight: 600;
    font-family: "Open sans", sans-serif;
    color: #F75A5A;
    border-bottom: 1px solid #F75A5A !important;
    line-height: 1.2;
    border: 1px solid transparent;
    user-select: none;
    background-color: transparent;
    &:hover {
        color: #F75A5A;
    }
}

.delete-btn {
    font-weight: 600;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    color: #F75A5A;
    border-bottom: 1px solid #F75A5A !important;
    line-height: 1.2;
    border: 1px solid transparent;
    user-select: none;
    background-color: transparent;
    &:hover {
        color: #F75A5A;
    }
}

.update-btn {
    font-weight: 600;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    color: #57B757;
    border-bottom: 1px solid #57B757 !important;
    line-height: 1.2;
    border: 1px solid transparent;
    user-select: none;
    background-color: transparent;
    &:hover {
        color: #57B757;
    }
}

.show-btn {
    font-weight: 600;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    line-height: 1.2;
    border: 1px solid transparent;
    user-select: none;
    background-color: transparent;

    &.do_not_show {
        color: #F75A5A;
        border-bottom: 1px solid #F75A5A !important;
        &:hover {
            color: #F75A5A;
        }
    }

    &.show {
        color: #57B757;
        border-bottom: 1px solid #57B757 !important;
        &:hover {
            color: #57B757;
        }
    }

}

#user-tasks,
#user-services,
#user-service-reviews {
    padding: 0 40px;
    border: 2px solid #2690C1;
    border-radius: 20px;
}

#page-bottom {
    background-color: #EFF9EF;
    border-bottom: 1px solid #57B757;
    min-height: 366px;

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 366px;
        max-width: 366px;
        background-size: 100%;
        background: url('/img/svg/decoration.svg');
        background-repeat: no-repeat;
    }

    &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        height: 100%;
        width: 366px;
        max-width: 366px;
        background-size: 100%;
        background: url('/img/svg/decoration.svg');
        background-repeat: no-repeat;
    }
}

.reviews-list {
    border-left: 1px solid #2690C1;
    padding-left: 50px;
    margin-left: 5px;

    .review {
        &:before {
            content: "";
            box-sizing: border-box;
            height: 17px;
            width: 17px;
            background-color: #FFF;
            border: 3px solid #2690C1;
            position: absolute;
            border-radius: 10px;
            left: 12px;
            margin-top: 10px;
        }

        margin-bottom: 40px;
        &:last-of-type {
            margin-bottom: 0;
        }

        .review-container {
            border-radius: 20px;
            background-color: #F2FBFF;
            box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
            padding: 30px 50px 30px 50px;


            .comment-text {
                padding-top: 10px;
            }
        }
    }
}



#copyright {
    border-top: 1px solid #E6E6E6;
    border-bottom: 5px solid #57B757;
    padding: 5px 5px 0px 5px;
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none;
}

#service-filters {
    .filter{
        border: 1px solid transparent;
        border-color: #CECECE;
        height: 50px;
        flex: 0 0 10%;
        &:focus {
            box-shadow: none;
        }

    }

    .dropdown-toggle {
        &:after{
            display: none;
        }
    }

    .input-group-prepend {
        &.custom-group {
            flex: 1 0 0;
            border-top: 1px solid #CECECE;
            border-bottom: 1px solid #CECECE;
        }
    }

    .prepend-input-text {
        border-top: 1px solid #CECECE;
        border-bottom: 1px solid #CECECE;
    }

    .b-left {
        border-left: 1px solid #CECECE;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-300;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray-300;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray-300;
    }
}

#task-filters {
    &.filter {
        border: 1px solid transparent;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-color: #CECECE;
    }

    .filter {
        height: 50px;
        flex: 0 0 10%;
        &:focus {
            box-shadow: none;
        }
    }

    .mobile-border {
        border: 1px solid transparent;
        border-radius: 8px;
        //border-bottom-left-radius: 8px;
        border-color: #CECECE;
    }

    .dropdown-toggle {
        &:after{
            display: none;
        }
    }

    .input-group-prepend {
        &.custom-group {
            flex: 1 0 0;
            border-top: 1px solid #CECECE;
            border-bottom: 1px solid #CECECE;
        }
    }

    .prepend-input-text {
        border-top: 1px solid #CECECE;
        border-bottom: 1px solid #CECECE;
    }


    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-300;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray-300;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray-300;
    }
}


.b-bottom {
    border-bottom: 1px solid #CECECE;
}

.b-top {
    border-top: 1px solid #CECECE;
}

.b-left {
    border-left: 1px solid #CECECE;
}

.b-right {
    border-right: 1px solid #CECECE;
}

.b-left-radius {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.b-right-radius {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

#service-form {
    #attribute-selects {
        margin-bottom: 30px;
        padding: 30px;
        border: 2px solid #2690C1;
        border-radius: 8px;
    }

    #service-price {
        padding-top: 1.875rem;
        padding-bottom: 2.5rem;
    }

    .fix-price-field,
    .range-price-fields {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .selected-address,
    .selected-attribute {
        border-radius: 8px;
        background-color: #F2F2F2 ;
        font-size: 16px;
        color: #2690C1;
        font-weight: 500;
        padding: 7px;
    }

    .attribute-price-container {
        padding: 2rem;
        border-radius: 8px;
        background-color: #F2F2F2;
    }

    .fixed-address-field {
        padding: 10px;
        height: 50px;
        width: 265px;
        border: 1px solid #CECECE;
    }

}

#service-search {
    input.form-control,
    select.form-control {
        border-color: #CECECE;
        height: 60px;
    }

    .prepend-input-text {
        border-top: 1px solid #CECECE;
        border-bottom: 1px solid #CECECE;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-300;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray-300;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray-300;
      }
}

#user-zone-search {
    input.form-control,
    select.form-control {
        border-color: #CECECE;
        height: 40px;
    }

    .input-group-append {
        height: 40px;
    }

    .prepend-input-text {
        height: 40px;
        border-top: 1px solid #CECECE;
        border-bottom: 1px solid #CECECE;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-300;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray-300;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray-300;
      }
}

#service-profile {
    border: 2px solid $blue;
    border-radius: 20px;
}

#service-attribute-list {
    border: 2px solid $blue;
    border-radius: 20px;
}

.border-right-unset {
    border-right: unset !important;
}

.border-left-unset {
    border-left: unset !important;
}

.border-top-unset {
    border-top: unset !important;
}

.border-bottom-unset {
    border-bottom: unset !important;
}

.btn-success {
    color: #FFF;
    font-weight: 700;
    padding: 10px 22px;
    border-radius: 8px;
}

.btn-primary {
    border-radius: 8px;
}

.border-gray-br {
    border-color: #E6E6E6 !important;
}

div.border-1:last-of-type,
div.border-bottom:last-of-type {
    border: unset !important;
}

.shadow-box {
    box-shadow: 1px 1px 3px #e4e7eb;
}

.shadow-bottom {
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

.h-screen{
    height: 100vh;
}

.h-input-40 {
    height: 40px;
}

.open-at-select {
    height: 40px;
    border: 1px solid #CECECE;
    border-right: unset;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.closed-at-select {
    height: 40px;
    border: 1px solid #CECECE;
    border-left: unset;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.h-input-50 {
    height: 50px;
}

.object-cover {
    object-fit: cover;
}

.object-left {
    object-position: left;
}

.auth-promo {
    object-position: -250px;
}


@media (min-width: 576px) {
    .location-model {
        max-width: 800px;
        margin: 1.75rem auto;
    }
}

.custom-modal {
    background-color:  rgba($color: #fff, $alpha: 0.95);
    border: 4px solid $green;
    border-radius: 20px;
    padding: 70px;
    max-width: 785px;

    .description {
        padding-top: 24px;
        padding-bottom: 32px;
    }
}

.form-control {
    border-radius: 0.625rem;
}

.disabled-bg {
    background-color: $gray-200;
}

textarea.form-control {
    border-color: #CECECE;
}

select {
    &:focus {
        outline: none;
    }

    &:disabled{
        opacity: 1;
    }
}

input.form-control,
select.form-control {
    border-color: #CECECE;
    height: 50px;
}

#global-search {
    .form-control {
        box-sizing: border-box;
        height: 60px;
        border-radius: 8px 8px 8px 8px;
        background-color: #FFF;
        border: 2px solid $green;

        &::-webkit-input-placeholder {
            color: #BDBDBD;
        }
    }

    button.btn {
        height: 60px;
        color: #FFF;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        border-radius: 0 8px 8px 0;
        background-color: $green;
    }

    .pill {
        border-radius: 136px;
        background-color: #FDFCFA;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
        color: #A1A9B3;
        font-family: "Open Sans";
        font-size: 14px;
        padding: 15px;
        margin-left: 10px;

        &:hover{
            cursor: pointer;
        }
    }

    .input-group-append {
        position: absolute;
        right: 0px;
    }
}


#user-zone {
    padding-top: 70px;
    padding-bottom: 70px;
}

#user-aside-menu {
    padding: 40px;
    border: 2px solid #2690C1;
    border-radius: 20px;
    background-color: #F2FBFF;
    a {
        line-height: 1;
    }

    .active {
        a {
            margin-left: 30px;
        }

        a,
        a span,
        span {
            color: #777;
        }

        &:before {
            content: '.';
            line-height: 0;
            font-size: 50px;
            position: absolute;
        }
    }

    div {
        .border-1,
        .border-bottom {
            &:last-of-type {
                border: unset !important;
            }
        }
    }

    div {
        &:first-of-type {
            padding-top: unset !important;
        }

        &:last-of-type {
            padding-bottom:  unset !important;
        }
    }

}

.tag {
    // padding: 3px 15px 3px 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    // min-width: 110px;
    background-color: #F2F2F2;
    border-radius: 8px;

    &.border-bd {
        border: 1px solid $gray-bd;
    }
}

.big-community-svg {
    display: block;
    height: 300px;
    &:after {
        content: "";
        position: absolute;
        left: 20%;
        height: inherit;
        width: 80%;
        background: url('/img/svg/big-community.svg');
        background-repeat: no-repeat;
    }
}

.save-time-svg {
    display: block;
    height: 300px;
    &:after {
        content: "";
        position: absolute;
        height: inherit;
        width: 80%;
        background: url('/img/svg/save-time.svg');
        background-repeat: no-repeat;
    }
}

.safe-svg {
    display: block;
    height: 300px;
    &:after {
        content: "";
        position: absolute;
        left: 20%;
        height: inherit;
        width: 80%;
        background: url('/img/svg/safe.svg');
        background-repeat: no-repeat;
    }
}

.page-item {
    &.active .page-link  {
        color: #788388;
        background-color: #E6E6E6;
    }
}

.pagination{
    .page-item {
        .page-link {
            cursor: pointer;
            width: 44px;
            height: 44px;
            padding: 10px;
            margin-left: 5px;
            text-align: center;
            line-height: 24px;
            border: none;
            border-radius: 100%;
            color: #788388;
        }
    }
}

.custom-accordion {
    .card {
        border-radius: 20px;
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0px;
        }
    }

    .card-header {
        border-bottom-width: 2px;
        &:first-child {
            border-radius: 20px;
        }
    }

    a {
        &:hover {
            color: $gray-400;
        }
        &[aria-expanded="false"] {
            .card-header {
                border-bottom: none !important;
            }
        }
    }
    a {
        &[aria-expanded="true"] {
            .flaticon-download {
                -webkit-transform: rotate(180deg);
                -moz-transform:    rotate(180deg);
                -ms-transform:     rotate(180deg);
                -o-transform:      rotate(180deg);
                transform:         rotate(180deg);
            }
        }
    }
}

.write-review {
    padding: 40px;
    background-color: #FBFBFB;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);

    h5 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    fieldset.rating {
        float: left;
        margin-bottom: 10px;

        label {
            color: $gray-300;
            margin-bottom: 0;
            cursor: pointer;
            float: right;

            i {
                margin-right: 4px;
                font-size: 25px;
                color: $star;
            }
        }

        & > input:checked ~ label i,
        &:not(:checked) > label:hover i,
        &:not(:checked) > label:hover ~ label i {
            opacity: 1 !important;

            &:not(.fa-star-half) {
                &:before {
                    content: "\f005";
                }
            }
        }
    }

    input[type="text"].form-control {
        height: 40px;
        padding: 7px 30px;
        border-radius: 10px;
        border-color: $green;
        margin-bottom: 15px;
    }

    .review-content {
        padding: 30px 40px;
        border-radius: 10px;
        border: 1px solid $green;
        background: $white;
        margin-bottom: 30px;

        &.comment {
            padding: 20px 30px
        }
    }
}

.review-content {
    textarea {
        border: none;
        border-radius: unset;
        margin: 4px 0 0 !important;
        padding: 0;
        width: 100%;
        resize: none;
        height: 100px;
        color: $gray-300;
    }
}

.page-custom-modal {
    background-color:  rgba($color: #fff, $alpha: 0.95);
    border: 4px solid $green;
    border-radius: 20px;
    padding: 40px;
    max-width: 50%;

    .description {
        padding-top: 30px;
        padding-bottom: 50px;
    }
}

.image-badge {
    position: absolute;
    background: rgb(100, 100, 100);
    bottom: 5px;
    right: 20px;
    line-height: 15px;
    color:white;
    border-radius: 15px;
}

button.address-empty {
    margin-left: 5px;
}

.tooltip-inner {
    text-align: left;
}

.word-break-all {
    word-break: break-all;
}

.nowrap {
    white-space: nowrap;
}

.w-max {
    width: max-content;
}
