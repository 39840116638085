.jui-control {
    border-radius: 1rem;
    background-color: #2690C1;
    border-color: #2690C1;
    outline: none;
}

#price-filter {
    .ui-slider-horizontal .ui-slider-handle {
        top: -.35rem
    }

    .ui-slider-horizontal {
        height: .5rem;
    }

    .ui-state-default {
        background-color: #2690C1;
        border-color: #2690C1;
    }
    

    .ui-widget-header {
        background-color: #2690C1;
        border: 1px solid #2690C1;
    }

    .ui-widget.ui-widget-content {
        background-color: #E5E5E5;
        border: none;
    }
}
