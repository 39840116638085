@import "./variables.scss";
@import "./custom.scss";
@import "./responsive.scss";

// custom elements
@import "../css/radio.css";
@import "../css/checkbox.css";
@import "./select2.scss";
@import "./nouislider.scss";
@import"./flatpickr.scss";
@import"./file-input-field.scss";

//packages css
@import "../css/imagelightbox.css";
@import "~jquery-ui/themes/base/slider.css";
@import "~jquery-ui/themes/base/theme.css";
@import "~select2/dist/css/select2.min.css";
@import"~nouislider/distribute/nouislider.css";
@import"~flatpickr/dist/themes/material_blue.css";
@import "./slider.scss";
@import "~toastr/toastr.scss";

//font awesome
$fa-font-path: "../fonts/font-awesome" !default;
@import "node_modules/font-awesome/scss/font-awesome.scss";