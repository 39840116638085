.custom-noUi-horizontal {
    height: 8px;
    border-radius: 6px;
}

.custom-noUi-connects,
.custom-noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.custom-noUi-horizontal {
    .custom-noUi-origin {
        height: 0;
    }

    .custom-noUi-handle {
        width: 20px;
        height: 20px;
        right: -17px;
        top: -6px;
    }
    
    .custom-noUi-tooltip {
        transform: translate(-50%, 0);
        left: 50%;
        bottom: 120%; 
    }
}

.custom-noUi-target{ 
    position: relative;
    background: #E5E5E5;
}

.custom-noUi-connect {
    background-color: #2690C1;
    height: 100%;
    width: 100%;
}

.custom-noUi-origin,
.custom-noUi-connect {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    -ms-transform-origin: 0 0;
    // -webkit-transform-origin: 0 0;
    // -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
    border-radius: 6px;
}

.custom-noUi-target,
.custom-noUi-target * {
    // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    // -webkit-user-select: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    box-sizing: border-box;
}

.custom-noUi-origin {
    height: 10%;
    width: 10%;
}



.custom-noUi-touch-area {
    height: 100%;
    width: 100%;
}

.custom-noUi-tooltip {
    font-size: 16px;
    display: block;
    position: absolute;
    border: 2px solid #2690C1;
    border-radius: 3px;
    background: #fff;
    color: #2690C1;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}

.custom-noUi-handle {
    border-radius: 10px;
    background: #2690C1;
    cursor: default;
    // -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}

