/* Customize the label (the checkbox-container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom checkbox */
.checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid #57B757;
    border-radius: 8px;
    background-color: #fff;
}

.checkbox-is-closed {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #CECECE;
    border-radius: 8px;
    background-color: #fff;
}
  
/* On mouse-over, add background color */
.checkbox-container:hover input ~ .checkbox-is-closed,
.checkbox-container:hover input ~ .checkbox-checkmark {
    background-color: #fff;
}
  
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-is-closed,
.checkbox-container input:checked ~ .checkbox-checkmark {
    background-color: #fff;
}
  
/* Create the checkbox-checkmark/indicator (hidden when not checked) */
.checkbox-is-closed:after,
.checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the checkbox-checkmark when checked */
.checkbox-container input:checked ~ .checkbox-is-closed:after,
.checkbox-container input:checked ~ .checkbox-checkmark:after {
    display: block;
}
  
/* Style the checkbox-checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
    left: 8px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: 2px solid #57B757;
    /* border-radius: 8px 0 0; */
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container  .checkbox-is-closed:after {
    left: 8px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: 2px solid #788287;
    /* border-radius: 8px 0 0; */
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}