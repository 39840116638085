.preview {
    display: none;
}

.upload-label-block {
    width: 100%;
    background-color: #eeeeee;
    border: 1px #cecece solid;
    border-style: dashed;
    cursor: pointer;

    &:before {
        content: "";
        display: block;
        padding-bottom: 50%;
    }

    &:after {
        content: "";
        display: block;
        padding-bottom: 50%;
    }
}

.remove-preview {
    display: none;
    font-size: 26px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    color: #F75A5A;
    position: absolute;
    top: -6px;
    right: 6px;

    i {
        cursor: pointer;
        position: absolute;
        top: -6px;
        right: 0px;
    }
}

.remove-media {
    display: block;
    font-size: 26px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    color: #F75A5A;
    position: absolute;
    top: -6px;
    right: 6px;

    i {
        cursor: pointer;
        position: absolute;
        top: -6px;
        right: 0px;
    }
}

.edit-media {
    display: block;
    font-size: 26px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #F75A5A;
    color: #000;
    position: absolute;
    top: -6px;
    right: 40px;

    i {
        cursor: pointer;
        position: absolute;
        top: -6px;
        right: 0px;
    }
}

.top-25 {
    top: 25px;
}


