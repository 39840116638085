/* Customize the label (the radio-container) */
.radio-container {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom radio button */
.radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid #57B757;
    border-radius: 50%;
}
 
.radio-blue .radio-checkmark {
    border-color: #2690C1 !important;
}
  
/* On mouse-over, add background color */
.radio-container:hover input ~ .radio-checkmark {
    background-color: #fff;
}
  
/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
    background-color: #fff;
    border: 2px solid #57B757;
}

.radio-container.radio-blue input:checked ~ .radio-checkmark {
    border: 2px solid #2690C1 !important;
}
  
/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
    display: block;
}
  
/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #57B757;
}

.radio-container.radio-blue .radio-checkmark:after {
    background: #2690C1;
}